<template>
  <div id="wrapper" class="cs-style">
    <Message></Message>
    <div id="page-wrapper" class="gray-bg dashbard-1">
      <div class="border-bottom">
        <Nav></Nav>
      </div>
      <div class="page-content-view">
        <!-- <template v-if="!['index', 'empty'].includes($route.name)">
                    <div
                        class="wrapper wrapper-con x"
                    >
                        <Breadcrumb></Breadcrumb>
                    </div>
                </template> -->
        <div
          class="wrapper wrapper-content"
          style="min-height: calc(100% - 100px)"
        >
          <!-- 路由占位符 -->
          <!--                    <keep-alive>-->
          <router-view />
          <!--                    </keep-alive>-->
        </div>
        <div class="wrapper-footer">
          <div class="info">
            <p>公司：郑州智慧园区科技运营有限公司</p>
            <p>地址：河南省郑州市二七区郑投科技创新园3号楼</p>
            <p>邮箱：contact@techops.cn</p>
          </div>
          <div class="bottom-logo">
              <HngySVG  />
              <HngyTextSVG style="margin: 0 2px 0 3px;" />
              <svg class="icon-font" aria-hidden="true" style="width: 80px; height: 70px;">
                  <use xlink:href="#icon-menua-zu4"></use>
              </svg>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Message from "@/components/Message.vue";
import { getShowRedDot } from "@/utils/index.js";
import { RED_DOT_TYPES } from "../../common/commonConst.js";
import * as icon from "../assets/shool.svg";
import HngySVG from "@/components/svgs/HngySVG";
import HngyTextSVG from "@/components/svgs/HngyTextSVG";

export default {
  name: "mainPage",
  components: {
      HngyTextSVG,
      HngySVG,
    Nav,
    // Breadcrumb,
    Message,
  },
  data() {
    return {
      scrollPos: 0,
      icon
    };
  },
  async mounted() {
    this.pollingTimer();
    let timer;
    window.addEventListener("resize", () => {
      if (!timer) {
        timer = setTimeout(() => {
          try {
            document.querySelectorAll(".result-panel").forEach((el) => {
              el.getElementsByClassName("custom-scroll-bar").forEach(
                (child) => {
                  el.removeChild(child);
                }
              );
            });
          } catch (err) {
            console.log(err, "删除元素错误");
          }
          clearTimeout(timer);
          timer = null;
        }, 200);
      }
    });

  },
  updated() {
      console.log('更新')
  },
  methods: {
    // 轮询定时器
    pollingTimer() {
      const types = Object.values(RED_DOT_TYPES);
      types.forEach((type) => {
        getShowRedDot(this, type);
      });
      setInterval(() => {
        types.forEach((type) => {
          getShowRedDot(this, type);
        });
      }, 1000 * 60 * 10);
    },
  },
};
</script>

<style lang="stylus">

.page-content-view {
  height: calc(100vh - 62px);
  overflow-y: auto;
  overflow-x: hide;
}

.wrapper-footer {
  width: 100%;
  height: 100px;
  background-color: #2F4050;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;

  .info {
    display: flex;
    flex-flow: column;

    p {
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
}

img {
  image-rendering: pixelated;
}
.bottom-logo{
    //width: 430px;
    box-sizing: border-box;
    display: flex;
    height: 70px;
    position:relative;
    justify-content: space-between;
    align-items: center;
}
.bottom-logo img:nth-child(1){
    margin-right: 10px
}
.bottom-logo img:nth-child(2){
  margin-right: 10px;
}
</style>
